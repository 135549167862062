'use strict';

class ChangePwController {
  constructor(Auth, $state, $http) {
    this.user = {};
    this.errors = {};
    this.submitted = false;
    this.key = $state.params.key;

    this.Auth = Auth;
    this.$state = $state;
    this.$http = $http;

    this.invalid = function(err) {
      console.log(err);
      alert('Errore: link scaduto o invalido. Rimanda email di reimpostazione password.');
      $state.go('pwReset');
    };

    this.$http({
      method: 'POST',
      url: '/api/pw-resets/status/',
      data: JSON.stringify({ key: this.key })
    }).then(res => {
      if(res.data.message !== 'ACTIVE') { this.invalid(res.data.message); }
    }).catch(this.invalid);
  }

  change(form) {
    this.submitted = true;
    this.error = function(err) {
      console.log(err);
      alert('Errore: riprova di nuovo tra qualche istante o rimanda email di reimpostazione password.');
    }

    if (form.$valid) {
      this.$http({
        method: 'POST',
        url: '/api/pw-resets/reset/',
        data: JSON.stringify({ key: this.key, password: this.user.password })
      }).then(res => {
        if(res.data.message === 'SUCCESS') {
          alert('Password cambiata con successo, fai il login con la tua nuova password.');
          this.$state.go('login');
        } else {
          this.error(res.data.message);
        }
      }).catch(this.error);
    }
  }
}

angular.module('appApp')
  .controller('ChangePwController', ChangePwController);
